<template>
  <div>
    <b-button
      @click="$router.go(-1)"
      variant="outline-warning"
      class="btn-icon my-1"
      size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
    <b-card class="" title="INFORMATIONS">
      <div class="row px-2 border-top">
        <b-card
          class="mb-0 block-text block-text-media"
          style="margin-right: 14%"
        >
          <div class="row mb-2">
            <b-icon-building
              class="h3 text-primary"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-building>
            <b-card-text>
              <span style="color: #b4b7bd">SOCIÉTÉ</span> <br />
              {{ manifeste.societe ? manifeste.societe.name : "" }}
            </b-card-text>
          </div>
          <div class="row">
            <b-icon-option
              class="h3"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-option>
            <b-card-text>
              <span style="color: #b4b7bd">NAVIRE</span> <br />
              {{ manifeste.navire ? manifeste.navire.name : "" }}
            </b-card-text>
          </div>
        </b-card>
        <b-card
          class="mb-0 block-text block-text-media"
          style="margin-right: 14%"
        >
          <div class="row mb-2">
            <b-icon-layers
              class="h3"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-layers>
            <b-card-text>
              <span style="color: #b4b7bd">IMPORTATEUR</span> <br />
              {{ manifeste.importateur ? manifeste.importateur.name : "" }}
            </b-card-text>
          </div>
          <div class="row">
            <b-icon-file
              class="h3"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-file>
            <b-card-text>
              <span style="color: #b4b7bd">CARTE IMPORTATEUR</span> <br />
              {{ $thousandSeparator(manifeste.prix_carte_imp) }}
            </b-card-text>
          </div>
        </b-card>
        <b-card class="mb-0 block-text">
          <div class="row mb-2">
            <b-icon-calendar-event
              class="h3 text-primary"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-calendar-event>
            <b-card-text>
              <span style="color: #b4b7bd">DATE D'ARRIVÉE</span> <br />
              {{ manifeste.date_arrivee }}
            </b-card-text>
          </div>
        </b-card>
      </div>
    </b-card>

    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div :hidden="hidden">
      <b-card class="mt-1 block-text" title="">
        <div class="border-bottom pb-2">
          <span class="h4" style="margin-top: 5px">LISTE DES VOITURES</span>
          <div class="float-right">
            <b-button
              size="sm"
              variant="outline-success"
              @click="printManifest"
            >
              <b-icon-printer-fill></b-icon-printer-fill>
              <span style="margin-left: 10px">Imprimer</span>
            </b-button>

            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-2"
              v-b-modal.modal-add-date-arrivee
              v-if="
                !manifeste.date_arrivee &&
                $hasFonction(user, 'Réceptionner manifeste carte importateur')
              "
              @click="editDateArriveeManifeste()"
            >
              <b-icon-check-circle-fill></b-icon-check-circle-fill>
              <span style="margin-left: 10px">Réceptionner</span>
            </b-button>
          </div>
        </div>

        <b-card no-body title="" class="mb-0 w-100">
          <div class="my-2 ml-1 row">
            <!-- search start -->
            <div class=" container-search search" style="width: 25%; margin-right: 58%">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
                class="col4 mr-5"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- bouton add start -->
            <div
              v-if="$hasFonction(user, 'Ajouter véhicule carte importateur') && !manifeste.status" 
            >
              <b-button variant="primary" v-b-modal.modal-add-vehicule>
                Ajouter un véhicule
              </b-button>
            </div>
            <!-- bouton add end -->
          </div>

          <!-- table start -->
          <b-table
            id="my-table"
			class="position-relative"
            responsive
            :fields="headers"
            :items="vehicules"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            show-empty
          >
            <template #cell(date_arrivee)="data">
              <!-- <div class="text-center"> -->
              {{ manifeste.date_arrivee ? manifeste.date_arrivee : "----" }}
              <!-- </div> -->
            </template>

            <template #cell(actions)="data">
              <div class="row justify-content-center">
                <div
                  v-if="
                    $hasFonction(user, 'Modifier véhicule carte importateur')
                  "
                >
                  <b-button
                    :id="'button-1' + data.item.id"
                    v-b-modal.modal-edit-vehicule
                    size="sm"
                    variant="flat-warning"
                    class="btn-icon"
                    @click="editVehicule(data.item)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-1' + data.item.id"
                    title="Editer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>

                <div
                  v-if="
                    $hasFonction(user, 'Supprimer véhicule carte importateur')
                  "
                >
                  <b-button
                    :id="'button-2' + data.item.id"
                    v-if="!manifeste.date_arrivee"
                    size="sm"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteVehicule(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-2' + data.item.id"
                    title="Supprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>

            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <span class="text-nowrap mr-2"> Montré de 1 à </span>
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2"> de {{ totalRows }} entrées </span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </b-card>

      <!-- modal edit vehicule start  -->
      <b-modal
        id="modal-edit-vehicule"
        v-model="modalEditVehicule"
        title="Modifier information voiture"
        @hidden="resetModalEditVehicule"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form
          class="mt-1"
          ref="editForm"
          @submit.stop.prevent="editHandleSubmit"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="numero_chassis"
              rules="required"
            >
              <label for="numero_chassis">Numéro chassis</label>
              <b-form-input
                id="numero_chassis"
                type="text"
                v-model="editVehiculeForm.numero_chassis"
                placeholder="Numéro chassis"
                @click="resetInputNumChassisModalEdit"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsNumChassisModalEdit
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="marque"
              rules="required"
            >
              <label for="marque">Marque</label>
              <model-list-select
              :list="marquesList"
              id="marque"
              v-model="editVehiculeForm.marque"
              option-value="id"
              option-text="name"
                @click="resetInputMarqueModalEdit"
              :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsMarqueModalEdit
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="couleur"
              rules="required"
            >
              <label for="couleur">Couleur</label>
              <model-list-select
              :list="couleursList"
              id="couleur"
              v-model="editVehiculeForm.couleur"
              option-value="id"
              option-text="name"
                @click="resetInputCouleurModalEdit"
              :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsCouleurModalEdit
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="annee"
              rules="required"
            >
              <label for="annee">Année</label>
              <b-form-input
                id="annee"
                type="number"
                v-model="editVehiculeForm.annee"
                placeholder="Année"
                @click="resetInputAnneeModalEdit"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsAnneeModalEdit
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalEditVehicule = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonEditVehicule"
              variant="primary"
              class="mt-2"
              @click="editHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal edit vehicule end-->

      <!-- modal add date arrivee start  -->
      <b-modal
        id="modal-add-date-arrivee"
        v-model="modalEditDateArriveeManifeste"
        title="Ajouter une date d'arrivée"
        @hidden="resetModalAddDateArrivee"
        hide-footer
        centered
      >
        <!-- form start -->
        <b-form
          class="mt-1"
          ref="addDateForm"
          @submit.stop.prevent="addDateHandleSubmit"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="date_arrivee"
              rules="required"
            >
              <b-form-datepicker
                v-model="editedDate.date_arrivee"
                placeholder="Date d'arrivée"
                labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                labelNoDateSelected="Aucune date sélectionnée"
                labelResetButton="Annuler"
                reset-button
                :max="maxDate"
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="resetInputDate()"
              ></b-form-datepicker>
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger">{{
                errors[0] ? "" : errorsDateArriveeModalAdd
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="modalEditDateArriveeManifeste = false"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonDateArrivee"
              variant="primary"
              class="mt-2"
              :disabled="isOperating"
              @click="addDateHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
        <!-- form end -->
      </b-modal>
      <!-- modal add date arrivee end-->
    </div>

    <div :hidden="hiddenEmpty" class="text-center">
      <empty-list></empty-list>
      <b-button v-b-modal.modal-add-vehicule variant="primary" size="sm">
        <b-icon-plus></b-icon-plus>
        AJOUTER UNE VOITURE
      </b-button>
    </div>

    <!-- modal add vehicule start  -->
    <b-modal
      id="modal-add-vehicule"
      v-model="modalAddVehicule"
      title="Ajouter une voiture"
      @hidden="resetModalAddVehicule"
      hide-footer
      centered
    >
      <!-- form start -->
      <b-form class="mt-1" ref="addForm" @submit.stop.prevent="addHandleSubmit">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="numero_chassis"
            rules="required"
          >
            <label for="numero_chassis">Numéro chassis</label>
            <b-form-input
              id="numero_chassis"
              type="text"
              v-model="addVehiculeForm.numero_chassis"
              placeholder="Numéro chassis"
              @click="resetInputNumChassisModalAdd"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsNumChassisModalAdd
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="marque"
            rules="required"
          >
            <label for="marque">Marque</label>
            <b-form-select
              id="marque"
              v-model="addVehiculeForm.marque"
              :options="marquesList"
              value-field="id"
              text-field="name"
              @click="resetInputMarqueModalAdd"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsMarqueModalAdd
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="couleur"
            rules="required"
          >
            <label for="couleur">Couleur</label>
            <b-form-select
              id="couleur"
              v-model="addVehiculeForm.couleur"
              :options="couleursList"
              value-field="id"
              text-field="name"
              @click="resetInputCouleurModalAdd"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsCouleurModalAdd
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="annee"
            rules="required"
          >
            <label for="annee">Année</label>
            <b-form-input
              id="annee"
              type="number"
              v-model="addVehiculeForm.annee"
              placeholder="Année"
              @click="resetInputAnneeModalAdd"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsAnneeModalAdd
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="prix_carte_imp"
            rules="required"
          >
            <label for="prix_carte_imp">Prix carte importateur</label>
            <cleave
              id="prix_carte_imp"
              v-model="addVehiculeForm.prix_carte_imp"
              class="form-control"
              :raw="false"
              placeholder="0"
              :options="numberFormat"
              @click="resetInputPrixCarteImpModalAdd"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger">{{
              errors[0] ? "" : errorsPrixCarteImpModalAdd
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mb-0">
          <b-button
            variant="outline-secondary"
            type="reset"
            class="mt-2 mr-1"
            @click="modalAddVehicule = false"
          >
            Annuler
          </b-button>

          <b-button
            v-if="etatButtonAddVehicule"
            variant="primary"
            class="mt-2"
            @click="addHandleOk"
          >
            Enregistrer
          </b-button>

          <b-button v-else variant="primary" class="mt-2">
            <b-icon-arrow-clockwise
              animation="spin"
              font-scale="1"
            ></b-icon-arrow-clockwise>
            En cours
          </b-button>
        </b-form-group>
      </b-form>
      <!-- form end -->
    </b-modal>
    <!-- modal add vehicule end-->
  </div>
</template>

<script>
import { mapState } from "vuex";

import { ModelListSelect } from "vue-search-select";
import Cleave from "vue-cleave-component";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BForm,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BIconLayers,
  BIconOption,
  BIconFile,
  BTooltip,
  BIconCheckCircleFill,
  BIconPrinterFill,
  BIconArrowClockwise,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    Cleave,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BTooltip,
    BFormDatepicker,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconBuilding,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    ModelListSelect,
    downloadExcel,
  },
  data() {
    return {
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      valueArrival: "",
      hidden: true,
      etatButtonDateArrivee: true,
      etatButtonAddVehicule: true,
      etatButtonEditVehicule: true,
      isOperating: false,
      hiddenEmpty: true,
      hiddenLoader: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      modalEditVehicule: false,
      modalAddVehicule: false,
      modalEditDateArriveeManifeste: false,
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      marquesList: [],
      couleursList: [],
      manifeste: [],
      vehicules: [],
      nameState: null,
      headers: [
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "text-center",
        },
        {
          key: "date_arrivee",
          label: "Date de réception",
          sortable: true,
          class: "text-center w-25",
        },
        {
          key: "marque",
          label: "Marque",
          sortable: true,
          class: "text-center w-25",
        },
        {
          key: "numero_chassis",
          label: "Chassis",
          sortable: true,
          class: "text-center w-25",
        },
        {
          key: "",
          label: "Carte importateur",
          sortable: true,
          class: "text-center w-25",
        },

      ],
      addVehiculeForm: {
        numero_chassis: "",
        marque: "",
        couleur: "",
        annee: "",
        prix_carte_imp: "",
        manifeste_id: window.sessionStorage.getItem("manifeste_id"),
      },
      editVehiculeForm: {
        id: 0,
        numero_chassis: "",
        marque: "",
        couleur: "",
        annee: "",
      },
      editedDate: {
        id: "",
        date_arrivee: "",
      },
      errorsNumChassisModalAdd: null,
      errorsMarqueModalAdd: null,
      errorsCouleurModalAdd: null,
      errorsAnneeModalAdd: null,
      errorsPrixCarteImpModalAdd: null,
      errorsNumChassisModalEdit: null,
      errorsMarqueModalEdit: null,
      errorsCouleurModalEdit: null,
      errorsAnneeModalEdit: null,
      errorsDateArriveeModalAdd: null,
    };
  },
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },
    totalRows() {
      // Set the initial number of items
      return this.vehicules.length;
    },
    ...mapState({
      user: (state) => state.user.auth,
    }),
  },
  created() {
    this.getManifeste();
    this.getMarques();
    this.getCouleurs();
  },
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    // add vehicule start
    resetModalAddVehicule() {
      this.addVehiculeForm.numero_chassis = "";
      this.addVehiculeForm.marque = "";
      this.addVehiculeForm.couleur = "";
      this.addVehiculeForm.annee = "";
      this.addVehiculeForm.prix_carte_imp = "";
      this.nameState = null;
      this.resetModalAddInput();
    },

    resetModalAddInput() {
      this.resetInputNumChassisModalAdd();
      this.resetInputMarqueModalAdd();
      this.resetInputCouleurModalAdd();
      this.resetInputAnneeModalAdd();
      this.resetInputPrixCarteImpModalAdd();
    },

    resetInputNumChassisModalAdd() {
      if (this.errorsNumChassisModalAdd) {
        this.errorsNumChassisModalAdd = null;
      }
    },

    resetInputMarqueModalAdd() {
      if (this.errorsMarqueModalAdd) {
        this.errorsMarqueModalAdd = null;
      }
    },

    resetInputCouleurModalAdd() {
      if (this.errorsCouleurModalAdd) {
        this.errorsCouleurModalAdd = null;
      }
    },

    resetInputAnneeModalAdd() {
      if (this.errorsAnneeModalAdd) {
        this.errorsAnneeModalAdd = null;
      }
    },

    resetInputPrixCarteImpModalAdd() {
      if (this.errorsPrixCarteImpModalAdd) {
        this.errorsPrixCarteImpModalAdd = null;
      }
    },
    // Add vehicule end

    // Edit vehicule start
    resetModalEditVehicule() {
      this.editVehiculeForm.id = "";
      this.editVehiculeForm.numero_chassis = "";
      this.editVehiculeForm.marque = "";
      this.editVehiculeForm.couleur = "";
      this.editVehiculeForm.annee = "";
      this.nameState = null;
      this.resetModalEditInput();
    },

    resetModalEditInput() {
      this.resetInputNumChassisModalEdit();
      this.resetInputMarqueModalEdit();
      this.resetInputCouleurModalEdit();
      this.resetInputAnneeModalEdit();
    },

    resetInputNumChassisModalEdit() {
      if (this.errorsNumChassisModalEdit) {
        this.errorsNumChassisModalEdit = null;
      }
    },

    resetInputMarqueModalEdit() {
      if (this.errorsMarqueModalEdit) {
        this.errorsMarqueModalEdit = null;
      }
    },

    resetInputCouleurModalEdit() {
      if (this.errorsCouleurModalEdit) {
        this.errorsCouleurModalEdit = null;
      }
    },

    resetInputAnneeModalEdit() {
      if (this.errorsAnneeModalEdit) {
        this.errorsAnneeModalEdit = null;
      }
    },
    // Edit vehicule end

    resetModalAddDateArrivee() {
      this.editedDate.date_arrivee = "";
      this.nameState = null;
      this.resetInputDate();
    },

    resetInputDate() {
      if (this.errorsDateArriveeModalAdd) {
        this.errorsDateArriveeModalAdd = null;
      }
    },

    getManifeste() {
      let id = window.sessionStorage.getItem("manifeste_id");

      this.$http.get("/carte-importateur/manifestes/" + id).then((result) => {
        if (result.status) {
          this.hiddenLoader = true;
        }

        this.manifeste = result.data.data;
        this.vehicules = result.data.data.vehicules;

        if (this.vehicules.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    getMarques() {
      this.$http
        .get("/marques")
        .then((res) => {
          this.marquesList = res.data.data.reverse();
        })
        .catch((e) => {});
    },

    getCouleurs() {
      this.$http.get("/couleurs").then((res) => {
        this.couleursList = res.data.data.reverse();
      });
    },

    // add vehicule start
    addCheckFormValidity() {
      const valid = this.$refs.addForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    addHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.addHandleSubmit();
    },

    addHandleSubmit() {
      if (!this.addCheckFormValidity()) {
        return;
      }

      this.addVehiculeForm.prix_carte_imp = this.$thousandFormater(
        this.addVehiculeForm.prix_carte_imp
      );
      this.etatButtonAddVehicule = false;

      if (!this.addVehiculeForm.numero_chassis) {
        this.errorsNumChassisModalAdd =
          "Veuillez renseigner le numero chassis !";
        this.etatButtonAddVehicule = true;
      } else {
        this.$http
          .post("/carte-importateur/ajouter-vehicule", this.addVehiculeForm)
          .then((result) => {
            if (result.data.success) {
              this.etatButtonAddVehicule = true;
              this.addVehiculeForm = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalAddVehicule = false;
              this.showToast("Véhicule enregistré avec succès", "success");
              this.getManifeste();
            } else {
              this.etatButtonAddVehicule = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonAddVehicule = true;
            var err = e.response.data.message;

            if (err) {
              if (err.numero_chassis) {
                this.errorsNumChassisModalAdd = err.numero_chassis[0];
              }
            }
          });
      }
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    // add vehicule end

    // Edit vehicule start
    editCheckFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    editHandleSubmit() {
      if (!this.editCheckFormValidity()) {
        return;
      }

      this.etatButtonEditVehicule = false;

      this.$http
        .put(
          "/carte-importateur/vehicules/" + this.editVehiculeForm.id,
          this.editVehiculeForm
        )
        .then((result) => {
          if (result.data.success) {
            this.etatButtonEditVehicule = true;
            this.editVehiculeForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEditVehicule = false;
            this.showToast("Véhicule modifié avec succès", "success");
            this.getManifeste();
          } else {
            this.etatButtonEditVehicule = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditVehicule = true;
          var err = e.response.data.message;
          // this.isOperating = false;

          if (err.numero_chassis) {
            this.errorsNumChassisModalEdit = err.numero_chassis[0];
          }

          if (err.marque) {
            this.errorsMarqueModalEdit = err.marque[0];
          }

          if (err.couleur) {
            this.errorsCouleurModalEdit = err.couleur[0];
          }

          if (err.annee) {
            this.errorsAnneeModalEdit = err.annee[0];
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    editHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.editHandleSubmit();
    },
    // Edit vehicule end

    checkFormValidity() {
      const valid = this.$refs.addDateForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    addDateHandleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.etatButtonDateArrivee = false;

      if (!this.editedDate.date_arrivee) {
        this.errorsDateArriveeModalAdd = "Veuillez sélectionner une date !";
        this.etatButtonDateArrivee = true;
      } else {
        this.$http
          .put(
            "/receptionner-manifeste-ci/" + this.editedDate.id,
            this.editedDate
          )
          .then((result) => {
            if (result.data.success) {
              this.etatButtonDateArrivee = true;
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditDateArriveeManifeste = false;
              this.showToast("Date enregistré avec succès", "success");
              this.getManifeste();
            } else {
              this.etatButtonDateArrivee = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonDateArrivee = true;
            var err = e.response.data.errors;

            if (err) {
              if (err.date_arrivee) {
                this.errorsDateArriveeModalAdd = err.date_arrivee[0];
              }
            } else if (e.response.status == 404) {
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditDateArriveeManifeste = false;
              this.showToast("Date enregistré avec succès", "success");
              this.getManifeste();
            }
          });
      }
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    addDateHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.addDateHandleSubmit();
    },

    addVehicule() {
      this.modalAddVehicule = true;
    },

    editVehicule(item) {
      this.editVehiculeForm.id = item.id;
      this.editVehiculeForm.numero_chassis = item.numero_chassis;
      this.editVehiculeForm.annee = item.annee;

      for (let i = 0; i < this.marquesList.length; i++) {
        if (this.marquesList[i].name == item.marque) {
          this.editVehiculeForm.marque = this.marquesList[i].id;
          i = this.marquesList.length;
        }
      }

      for (let i = 0; i < this.couleursList.length; i++) {
        if (this.couleursList[i].name == item.couleur) {
          this.editVehiculeForm.couleur = this.couleursList[i].id;
          i = this.couleursList.length;
        }
      }

      this.modalEditVehicule = true;
    },

    editDateArriveeManifeste() {
      let id = window.sessionStorage.getItem("manifeste_id");

      this.editedDate.id = id;
      this.modalEditDateArriveeManifeste = true;
    },

    deleteVehicule(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des voitures ?",
          {
            title: "Supprimer une voiture",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/carte-importateur/vehicules/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getManifeste();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },

    printManifest() {
      let routeData = this.$router.resolve({
        name: "importation-print",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
.block-text .card-body {
  padding-bottom: 0px;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

@media screen and (max-width: 1000px) {
  .search {
    margin-right: 50% !important;
  }
  .block-text-media {
    margin-right: 5% !important;
  }
}

@media screen and (max-width: 435px) {
	.container-search {
		width: initial !important;
		margin-right: 0px !important;
	}
}
</style>